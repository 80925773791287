var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InfoBanner',{attrs:{"module_name":_vm.module_name}}),_c('PageTitle',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Сотрудники ")]},proxy:true},{key:"custom-btn",fn:function(){return [_c('v-btn',{staticClass:"mr-6",attrs:{"color":"error darken-4","depressed":"","disabled":!(_vm.is_partner_manager || _vm.is_vice_partner_manager)},on:{"click":_vm.open_invite_dialog}},[_vm._v(" Отправить приглашение ")])]},proxy:true}])}),(_vm.info_message())?_c('InfoAlert',{staticClass:"mb-5",attrs:{"is_dark":""},scopedSlots:_vm._u([{key:"alert_text",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.info_message())}})]},proxy:true}],null,false,631939790)}):_vm._e(),_c('v-card',{class:_vm.$style.card},[_c('StaffsPageFilters',{on:{"filter_update":_vm.filter_update},scopedSlots:_vm._u([{key:"btn-outer",fn:function(){return [_c('v-btn',{class:[_vm.$style.btnOuter, 'mb-0'],attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1)]},proxy:true}])}),_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{class:_vm.$style.cartsList,attrs:{"headers":_vm.filtered_cols,"items":_vm.staffs,"loading":_vm.staff_loading,"options":_vm.table_options,"expanded":_vm.expanded,"custom-sort":_vm.prevent_sort,"hide-default-footer":"","no-data-text":"Сотрудники, удовлетворяющие выбранным параметрам, отсутствуют"},on:{"update:options":function($event){_vm.table_options=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.full_name)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.full_name)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.full_name)+" ")])],1)]}},{key:"item.roles_string",fn:function(ref){
var item = ref.item;
return [(item.is_incompatible_roles)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"warning darken-3"}},[_vm._v("mdi-alert")])],1)]}}],null,true)},[_vm._v(" Несовместимый набор ролей ")]):_vm._e(),_vm._v(" "+_vm._s(item.roles_string)+" ")]}},{key:"item.is_veryfied",fn:function(ref){
var item = ref.item;
return [(item.is_registered)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.is_active_account)?_c('ActiveUserIcon',_vm._g(_vm._b({},'ActiveUserIcon',attrs,false),on)):_c('DeactivateUserIcon',_vm._g(_vm._b({},'DeactivateUserIcon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.is_active_account ? 'Активна' : 'Неактивна')+" ")])])],1):_vm._e()]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",class:_vm.$style.contacts_min_width},[_c('div',{staticClass:"d-flex align-center",class:_vm.$style.is_mobile},[(item.email)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.email)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.email || '')+" ")])],1),_c('div',{staticClass:"d-flex align-center",class:_vm.$style.is_mobile},[(item.telephone)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.telephone)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.telephone || '')+" ")])],1)])]}},{key:"item.verification_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(item.verification_date))+" ")])]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.city)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.full_name.trim().length)?[(_vm.activity_access(item))?[_c('v-btn',{attrs:{"color":"#000","icon":"","title":"Продлить действие учетной записи"},on:{"click":function($event){$event.stopPropagation();return _vm.set_activity(item.id)}}},[_c('v-icon',[_vm._v("mdi-history")])],1),_c('v-btn',{attrs:{"color":"#000","icon":"","title":"Деактивировать учетную запись","disabled":!item.is_veryfied},on:{"click":function($event){$event.stopPropagation();return _vm.set_deactivate(item)}}},[_c('v-icon',[_vm._v("mdi-account-off-outline")])],1)]:_vm._e(),(_vm.is_partner_manager)?_c('v-btn',{attrs:{"color":"#000","icon":"","title":"Передать роль руководителя","disabled":!(item.is_active && item.is_active_account)},on:{"click":function($event){$event.stopPropagation();return _vm.set_manager_change(item)}}},[_c('v-icon',[_vm._v("mdi-account-arrow-right-outline")])],1):_vm._e()]:[(_vm.is_partner_manager || _vm.is_vice_partner_manager)?[_c('v-btn',{attrs:{"color":"#000","icon":"","title":"Отправить приглашение заново"},on:{"click":function($event){$event.stopPropagation();return _vm.send_again(item)}}},[_c('v-icon',[_vm._v("mdi-sync")])],1),_c('v-btn',{attrs:{"color":"#000","icon":"","title":"Удалить приглашение"},on:{"click":function($event){$event.stopPropagation();return _vm.delete_invite(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]:_vm._e()]]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pl-8 pr-4 py-3 text-body-1",attrs:{"colspan":headers.length}},[_c('div',{class:_vm.$style.expandedItem},[_c('span',{class:_vm.$style.expandedText},[_vm._v(" Дата рождения: ")]),_c('span',{class:_vm.$style.expandedVal},[_vm._v(" "+_vm._s(_vm._f("date")(item.birth_date))+" ")])])])]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)})],2),_c('DialogNewInvite',{on:{"close":function($event){_vm.invite_dialog = false},"submit":_vm.send_invite},model:{value:(_vm.invite_dialog),callback:function ($$v) {_vm.invite_dialog=$$v},expression:"invite_dialog"}}),_c('ActivityStaffDialog',{ref:"activity_dialog"}),_c('ConfirmDialog',{ref:"confirm"}),_c('CustomColsDialog',{attrs:{"cols":_vm.cols,"cols_list":_vm.cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols=$event},"save_cols":function($event){_vm.cols_dialog = false},"cancel_dialog":function($event){_vm.cols_dialog = false},"close":function($event){_vm.cols_dialog = false},"default_limit":_vm.default_limit},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}}),_c('ChangeManagerDialog',{on:{"close":_vm.close_change_model,"confirm_and_close":_vm.confirm_and_close},model:{value:(_vm.change_model),callback:function ($$v) {_vm.change_model=$$v},expression:"change_model"}}),_c('ConfirmChangeDialog',{on:{"close":_vm.close_and_logout},model:{value:(_vm.confirm_change_model),callback:function ($$v) {_vm.confirm_change_model=$$v},expression:"confirm_change_model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }